import {
    DoppeActionCTAButton,
    doppeActionCtaButtonTypeGetDefaultSettings,
    DoppeActionCTAType,
    DoppeActionCTATypeLinkAutoSource,
    DoppeActionCTATypeLinkOpenType,
    DoppeActionSettingsCTAButtonSettings
} from './doppe-action-cta-button';
import {autoValueCreate, evaluateFunction} from '@wix/devzai-utils-common';
import {DoppeHideableValue} from './doppe-hideable-value';

export interface DoppeActionCtaButtonSettings {
    ctaButton: DoppeActionCTAButton.SingleButton;
}

export function getDefaultDoppeActionCtaButtonSettings(options: {
    buttonText?: DoppeHideableValue<string>,
    url?: string,
    showButton: boolean
    settings?: DoppeActionSettingsCTAButtonSettings
}): DoppeActionCtaButtonSettings {

    const {
        buttonText,
        url,
        showButton,
        settings
    } = options;


    return {
        ctaButton: {
            settings: settings ?? {
                type: DoppeActionCTAType.Link,
                url: url !== undefined ? url : autoValueCreate(DoppeActionCTATypeLinkAutoSource.SameAsSourceURL),
                openType: DoppeActionCTATypeLinkOpenType.NewTab
            },
            text: buttonText ? buttonText : '',
            showButton: showButton,
        }
    }
}

export function doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration<T extends DoppeActionCTAButton.BaseType>(
    options: {
        ctaButton?: T,
        hasSourceURL: boolean
    }
) {

    const {
        ctaButton,
        hasSourceURL
    } = options;

    if (!ctaButton) {
        return undefined;
    }

    const defaultSettings = doppeActionCtaButtonTypeGetDefaultSettings(ctaButton.settings.type, hasSourceURL);

    const settings = evaluateFunction(() => {
        switch (ctaButton.settings.type) {
            case DoppeActionCTAType.Checkout: {

                const {
                    checkoutSettings,
                    ...restSettings
                } = ctaButton.settings;

                const {
                    checkoutSettings: defaultCheckoutSettings,
                    ...restDefaultSettings
                } = defaultSettings as DoppeActionSettingsCTAButtonSettings.Checkout;

                return {
                    ...restDefaultSettings,
                    checkoutSettings: {
                        ...defaultCheckoutSettings,
                        ...checkoutSettings,
                    },
                    ...restSettings,
                } as DoppeActionSettingsCTAButtonSettings.Checkout;
            }
            case DoppeActionCTAType.Whatsapp: {
                return {
                    ...defaultSettings,
                    ...ctaButton.settings,
                } as DoppeActionSettingsCTAButtonSettings.Whatsapp;
            }
            case DoppeActionCTAType.Call: {
                return {
                    ...defaultSettings,
                    ...ctaButton.settings,
                } as DoppeActionSettingsCTAButtonSettings.Call;
            }
            case DoppeActionCTAType.Share: {
                return {
                    ...defaultSettings,
                    ...ctaButton.settings,

                } as DoppeActionSettingsCTAButtonSettings.Share;
            }
            case DoppeActionCTAType.Link: {
                return {
                    ...defaultSettings,
                    ...ctaButton.settings,
                } as DoppeActionSettingsCTAButtonSettings.Link;
            }
            case DoppeActionCTAType.IsracardPay: {
                return {
                    ...defaultSettings,
                    ...ctaButton.settings,
                } as DoppeActionSettingsCTAButtonSettings.IsracardPay;
            }
            case DoppeActionCTAType.OpenProduct: {
                return {
                    ...defaultSettings,
                    ...ctaButton.settings,
                } as DoppeActionSettingsCTAButtonSettings.OpenProduct;
            }
            case DoppeActionCTAType.OpenPage: {
                return {
                    ...defaultSettings,
                    ...ctaButton.settings,
                } as DoppeActionSettingsCTAButtonSettings.OpenPage;
            }
            case DoppeActionCTAType.Email: {
                return {
                    ...defaultSettings,
                    ...ctaButton.settings,
                } as DoppeActionSettingsCTAButtonSettings.Email;
            }
            case DoppeActionCTAType.Navigate: {
                return {
                    ...defaultSettings,
                    ...ctaButton.settings,
                } as DoppeActionSettingsCTAButtonSettings.Navigate;
            }
            case DoppeActionCTAType.FacebookMessage: {
                return {
                    ...defaultSettings,
                    ...ctaButton.settings,
                } as DoppeActionSettingsCTAButtonSettings.FacebookMessage;
            }
            case DoppeActionCTAType.InstagramMessage: {
                return {
                    ...defaultSettings,
                    ...ctaButton.settings,
                } as DoppeActionSettingsCTAButtonSettings.InstagramMessage;
            }
            case DoppeActionCTAType.SaveContact: {
                return {
                    ...defaultSettings,
                    ...ctaButton.settings,
                } as DoppeActionSettingsCTAButtonSettings.SaveContact;
            }

        }
    })

    return {
        ctaButton: {
            ...ctaButton,
            settings: settings
        } as T
    }
}


