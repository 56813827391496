import {DoppeActionLayout} from './doppe-action-layout';
import {DoppeActionListItemClickBehaviour} from './doppe-action-list-item-click-behaviour';
import {DoppeDtoAction, doppeDtoActionGetDefaultSearchListSettingsProps} from './doppe-dto-action';
import {WixImageResource, WixMediaResource, wixMediaResourceReplaceExternalImage} from '@wix/devzai-common-wix';
import {
    DoppeHideableValue,
    doppeHideableValueCreateHidden,
    doppeHideableValueGetValue,
    doppeHideableValueIsVisibleAndNotEqualValue,
    doppeHideableValueModifyValue
} from './doppe-hideable-value';
import {BymoPageActionMediaViewLayout, BymoPageActionMediaViewSettings, BymoPageMedia,} from '../bymo-pages';
import {
    assertDefined,
    objectForEachValue,
    OmitStrict,
    PartiallyRequired,
    PickByValueExact,
    Values
} from '@wix/devzai-utils-common';
import {
    DoppeActionCtaButtonSettings,
    doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration,
    getDefaultDoppeActionCtaButtonSettings
} from './doppe-action-cta-button-settings';
import {DoppeActionContentSection, doppeActionContentSectionGetDefaultValues} from './doppe-action-content-section';
import {
    doppeActionContentSectionDefaultValuesMigration,
    doppeActionContentSectionSettingsDefaultValuesMigration,
    DoppeActionContentSectionsSettings
} from './doppe-action-content-sections-settings';
import {doppeActionGetMediaViewDefaultSettingsMigration} from './doppe-action-media-view-settings';
import {
    DoppeActionCTAButton,
    doppeActionCtaButtonCreateFromListAndOverrideId,
    doppeActionCtaButtonResetSettingsForLockNew,
    DoppeActionCTAType
} from './doppe-action-cta-button';
import {
    DoppeActionCtaButtonsSettings,
    doppeActionCtaButtonsSettingsDefaultValuesMigration
} from './doppe-action-cta-buttons-settings';
import ListItemWithCtaButtons = DoppeListActionSettings.ListItemWithCtaButtons;
import ListItemWithSections = DoppeListActionSettings.ListItemWithSections;

export type DoppeListActionSettings<ItemSettings extends DoppeListActionSettings.ListItemBaseSettings = any> = {
        listDataItems: ItemSettings[];
        itemShowDescription: boolean;
        itemShowImage: boolean;
        itemShowTitle: boolean;
        itemShowTextOnImage: boolean;
        layout: DoppeActionLayout;
        latestNonPremiumLayout?: DoppeActionLayout;
    }
    & DoppeActionCtaButtonsSettings
    & DoppeActionContentSectionsSettings
    & (ItemSettings extends DoppeListActionSettings.ListItemWithSections ? DoppeListActionSettings.WithListItemTemplateSections : {})
    & (ItemSettings extends DoppeListActionSettings.ListItemWithCtaButtons ? DoppeListActionSettings.WithListItemTemplateCtaButtons : {})

export const DoppeListActionSettingsContentSectionAutoValue = {
    ListTemplateSections: 'ListTemplateSections',
} as const;

export type DoppeListActionSettingsContentSectionAutoValue = Values<typeof DoppeListActionSettingsContentSectionAutoValue>;


export const DoppeListActionSettingsCtaButtonAutoValue = {
    ListTemplateButtons: 'ListTemplateButtons',
} as const;

export type DoppeListActionSettingsCtaButtonAutoValue = Values<typeof DoppeListActionSettingsCtaButtonAutoValue>;


export namespace DoppeListActionSettings {


    export interface ListItemBaseSettings {
        id: string;
        enabled: boolean;
        deleted: boolean;
        displayInSearch: boolean;
    }

    export type SectionListItem = DoppeActionContentSection | DoppeListActionSettingsContentSectionAutoValue;

    export type ListItemWithSections = {
        sectionsOverridesMap: Record<string, DoppeActionContentSection.Override>;
        customSections: SectionListItem[];
    }

    export type CtaButtonListItem = DoppeActionCTAButton.MultipleButton | DoppeListActionSettingsCtaButtonAutoValue;

    export type ListItemWithCtaButtons = {
        ctaButtonsOverridesMap: Record<string, DoppeActionCTAButton.MultipleButtonOverride>;
        customCtaButtons: CtaButtonListItem[];
    }

    export interface DeprecatedFields {
        showButton: boolean;
        buttonText: string;
        buttonUrl: string;
    }

    export type ResolveListItemSettings<
        T extends DoppeListActionSettings,
        K extends keyof T & string
    > = T[K] extends Array<infer U> ? U : never;

    export type ResolvePartialAndDeprecatedActionSettings<
        T extends DoppeListActionSettings,
        V extends { id: string },
        K extends keyof PickByValueExact<T, V[]> & string
    > = Partial<OmitStrict<T, K>> & {
        [key in K]?: PartiallyRequired<Partial<V>, 'id'>[]
    } & DoppeListActionSettings.DeprecatedFields;

    export interface WithExternalDataSourceSupport<T extends {}> {
        externalDataSource: null | T;
        externalDataSourceItemsCount: number;
    }

    export interface WithCtaButton extends DoppeActionCtaButtonSettings {

    }

    export interface WithListItemTemplateCtaButtons {
        listItemTemplateCtaButtonsSettings: DoppeActionCtaButtonsSettings;
    }

    export interface WithListItemTemplateSections {
        listItemTemplateSectionsSettings: DoppeActionContentSectionsSettings;
    }

    export interface WithMainDetails {
        mainImage: DoppeHideableValue<WixMediaResource | null>;
        mainMediaViewSettings: BymoPageActionMediaViewSettings;
        mainMediaViewLayout: BymoPageActionMediaViewLayout;
        mainTitle: DoppeHideableValue<string>;
    }

    export interface WithItemClickBehaviour {
        itemClickBehaviour: DoppeActionListItemClickBehaviour;
    }


    export interface WithSearchSettings {
        listSearchSettings: DoppeDtoAction.ListSearchSettings;
    }
}

export function doppeListActionItemCtaButtonsResetSettingsForLock(actionSettings: DoppeListActionSettings<DoppeListActionSettings.ListItemWithCtaButtons & DoppeListActionSettings.ListItemBaseSettings>) {
    actionSettings.listDataItems.forEach(listItem => {
        listItem.customCtaButtons.forEach(customCtaButton => {
            if (customCtaButton !== DoppeListActionSettingsCtaButtonAutoValue.ListTemplateButtons) {
                doppeActionCtaButtonResetSettingsForLockNew(customCtaButton);
            }
        });

        objectForEachValue(listItem.ctaButtonsOverridesMap, overrideButton => {
            doppeActionCtaButtonResetSettingsForLockNew(overrideButton);
        });
    });

    actionSettings.listItemTemplateCtaButtonsSettings.ctaButtons.forEach(ctaButton => {
        doppeActionCtaButtonResetSettingsForLockNew(ctaButton);
    });
}


export function doppeListActionTemplateCtaButtonsSettingsDefaultValuesMigration(ctaButtonsSettings: Partial<DoppeListActionSettings.WithListItemTemplateCtaButtons>, hasSourceURL: boolean) {

    const {
        ctaButtons,
        ...restSettings
    } = ctaButtonsSettings?.listItemTemplateCtaButtonsSettings ?? {}

    return {
        listItemTemplateCtaButtonsSettings: {
            ...restSettings,
            ctaButtons: ctaButtons ? ctaButtons.map(ctaButton => {
                return assertDefined(doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                    ctaButton: ctaButton,
                    hasSourceURL: hasSourceURL
                })).ctaButton
            }) : []
        }
    } satisfies DoppeListActionSettings.WithListItemTemplateCtaButtons;
}

export function doppeListActionTemplateSectionsSettingsDefaultValuesMigration(sectionsSettings: Partial<DoppeListActionSettings.WithListItemTemplateSections>) {

    const {
        sections,
        ...restSettings
    } = sectionsSettings?.listItemTemplateSectionsSettings ?? {}

    return {
        listItemTemplateSectionsSettings: {
            ...restSettings,
            sections: sections ? sections.map(section => {
                return doppeActionContentSectionDefaultValuesMigration(section);
            }) : []
        }
    } satisfies DoppeListActionSettings.WithListItemTemplateSections;
}

export function doppeListActionListItemWithSectionsMigration(overrides?: Partial<DoppeListActionSettings.ListItemWithSections>) {

    const sectionsOverridesMap = overrides?.sectionsOverridesMap ?? {};
    objectForEachValue(sectionsOverridesMap, (partialContentSection) => {

        const {
            content,
            ...resetPartialContentSection
        } = partialContentSection;

        return {
            content: {
                ...doppeActionContentSectionGetDefaultValues(content.type),
                ...content
            },
            ...resetPartialContentSection
        } satisfies  DoppeActionContentSection.Override
    })

    const customSections = overrides?.customSections ?? [DoppeListActionSettingsContentSectionAutoValue.ListTemplateSections]
    const hasListTemplateSections = customSections.includes(DoppeListActionSettingsContentSectionAutoValue.ListTemplateSections);

    if (!hasListTemplateSections) {
        customSections.push(DoppeListActionSettingsContentSectionAutoValue.ListTemplateSections);
    }

    return {
        customSections: customSections.map(listItemSection => {
            if (listItemSection === DoppeListActionSettingsContentSectionAutoValue.ListTemplateSections) {
                return listItemSection;
            } else {
                return doppeActionContentSectionDefaultValuesMigration(listItemSection)
            }
        }),
        sectionsOverridesMap: sectionsOverridesMap,
    } satisfies DoppeListActionSettings.ListItemWithSections;
}

export function doppeListActionListItemCalculateItemSections(sectionsSettings: DoppeActionContentSectionsSettings, itemSettings: DoppeListActionSettings.ListItemWithSections) {

    const sections: DoppeActionContentSection.ContentSectionWithOverride[] = [];

    itemSettings.customSections.forEach(listItemSection => {
        if (listItemSection === DoppeListActionSettingsContentSectionAutoValue.ListTemplateSections) {
            sectionsSettings.sections.forEach(listSection => {
                const override = itemSettings.sectionsOverridesMap[listSection.id];

                const {
                    content,
                    ...restOverrideSettings
                } = override ?? {};

                const {
                    content: listSectionSettings,
                    ...restListCtaButton
                } = listSection

                sections.push({
                    ...restListCtaButton,
                    ...restOverrideSettings,
                    content: (!!content && !restOverrideSettings.useDefaultSectionContent ? content : listSectionSettings),
                    isDefaultSection: true
                })
            });
        } else {
            sections.push({
                ...listItemSection,
                useDefaultSectionContent: false,
                isDefaultSection: false
            });
        }
    })

    return {
        sections: sections
    }
}

export function doppeListActionListItemWithCtaButtonsMigration(overrides: Partial<DoppeListActionSettings.ListItemWithCtaButtons>, hasSourceURL: boolean) {

    const ctaButtonsOverridesMap = overrides.ctaButtonsOverridesMap ?? {};
    objectForEachValue(ctaButtonsOverridesMap, (ctaButtonOverride) => {

        const ctaButtonSettings = assertDefined(doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
            ctaButton: ctaButtonOverride,
            hasSourceURL: hasSourceURL
        }));

        return {
            settings: ctaButtonSettings.ctaButton.settings,
            isVisible: ctaButtonOverride.isVisible,
            useDefaultContent: ctaButtonOverride.useDefaultContent,
        } satisfies  DoppeActionCTAButton.MultipleButtonOverride
    })

    const customCtaButtonsValue = overrides.customCtaButtons ?? [DoppeListActionSettingsCtaButtonAutoValue.ListTemplateButtons];
    const hasListTemplateButtons = customCtaButtonsValue.includes(DoppeListActionSettingsCtaButtonAutoValue.ListTemplateButtons);

    if (!hasListTemplateButtons) {
        customCtaButtonsValue.push(DoppeListActionSettingsCtaButtonAutoValue.ListTemplateButtons);
    }

    return {
        customCtaButtons: customCtaButtonsValue.map(listItemButton => {
            if (listItemButton === DoppeListActionSettingsCtaButtonAutoValue.ListTemplateButtons) {
                return listItemButton;
            } else {
                return assertDefined(doppeActionCtaButtonTypeGetDefaultCtaButtonSettingsMigration({
                    ctaButton: listItemButton,
                    hasSourceURL: hasSourceURL
                })).ctaButton;
            }
        }),
        ctaButtonsOverridesMap: ctaButtonsOverridesMap,
    } satisfies DoppeListActionSettings.ListItemWithCtaButtons;
}

export function doppeListActionListItemCalculateItemButtons(ctaButtonsSettings: DoppeActionCtaButtonsSettings, itemSettings: DoppeListActionSettings.ListItemWithCtaButtons & DoppeListActionSettings.ListItemBaseSettings) {

    const ctaButtons: DoppeActionCTAButton.MultipleButtonWithOverride[] = [];


    itemSettings.customCtaButtons.forEach(listItemCtaButton => {
        if (listItemCtaButton === DoppeListActionSettingsCtaButtonAutoValue.ListTemplateButtons) {
            ctaButtonsSettings.ctaButtons.map(listCtaButton => {

                const override = itemSettings.ctaButtonsOverridesMap[listCtaButton.id];

                const {
                    settings,
                    ...restOverrideSettings
                } = override ?? {};

                const {
                    settings: listCtaButtonSettings,
                    ...restListCtaButton
                } = listCtaButton;

                ctaButtons.push({
                    ...restListCtaButton,
                    ...restOverrideSettings,
                    settings: caclulateCtaButtonOverrideSettings(listCtaButton, override),
                    isDefaultButton: true,
                    id: doppeActionCtaButtonCreateFromListAndOverrideId(itemSettings.id, listCtaButton.id)
                })
            })
        } else {
            ctaButtons.push({
                ...listItemCtaButton,
                useDefaultContent: false,
                isDefaultButton: false
            });
        }
    })

    return {
        ctaButtons: ctaButtons
    }
}


function caclulateCtaButtonOverrideSettings(ctaButton: DoppeActionCTAButton.MultipleButton, override?: DoppeActionCTAButton.MultipleButtonOverride) {

    if (ctaButton.settings.type === DoppeActionCTAType.Checkout) {

        return {
            ...ctaButton.settings,
            ...(!!override?.settings && !override.useDefaultContent ? override.settings : {}),
            checkoutSettings: ctaButton.settings.checkoutSettings
        }
    }
    else {
        return {
            ...ctaButton.settings,
            ...(!!override?.settings && !override.useDefaultContent ? override.settings : {})
        }
    }



}

export function doppeListActionListItemNormalizeSectionsOverridesMap(items: DoppeActionContentSection[], sectionsOverridesMap: Record<string, DoppeActionContentSection.Override>) {


    const newSectionsOverridesMap: Record<string, DoppeActionContentSection.Override> = {};

    items.forEach(sectionSettings => {
        newSectionsOverridesMap[sectionSettings.id] = {
            ...sectionsOverridesMap[sectionSettings.id],
            content: sectionSettings.content,
            isVisible: sectionSettings.isVisible,
        }
    })

    return newSectionsOverridesMap;
}

export function doppeListActionListItemNormalizeCtaButtonsOverridesMap(items: DoppeActionCTAButton.MultipleButton[], ctaButtonsOverridesMap: Record<string, DoppeActionCTAButton.MultipleButtonOverride>) {


    const newCtaButtonsOverridesMap: Record<string, DoppeActionCTAButton.MultipleButtonOverride> = {};

    items.forEach(ctaButton => {
        newCtaButtonsOverridesMap[ctaButton.id] = {
            ...ctaButtonsOverridesMap[ctaButton.id],
            settings: ctaButton.settings,
            isVisible: ctaButton.isVisible,
        }
    })

    return newCtaButtonsOverridesMap
}

export function doppeListActionWithMainDetailsGetMainImage(settings: DoppeListActionSettings.WithMainDetails, listItemImage: BymoPageMedia | null) {
    return doppeHideableValueIsVisibleAndNotEqualValue(settings.mainImage, null) ? settings.mainImage : listItemImage;

}

export function doppeListActionWithMainDetailsReplaceMainImage(actionSettings: DoppeListActionSettings.WithMainDetails, externalImagesUrlToImageResourceMap: Map<string, WixImageResource>) {
    const mainImage = doppeHideableValueGetValue(actionSettings.mainImage);
    if (mainImage) {
        actionSettings.mainImage = doppeHideableValueModifyValue(actionSettings.mainImage, () => {
            return wixMediaResourceReplaceExternalImage(mainImage, externalImagesUrlToImageResourceMap)
        })
    }
}

export function doppeListActionGetDefaultListSettings(overrides: Partial<DoppeListActionSettings>) {

    const {
        ...restOverrides
    } = overrides ?? {}

    return {
        listDataItems: [],
        layout: DoppeActionLayout.Column,
        itemShowDescription: true,
        itemShowTitle: true,
        itemShowImage: true,
        itemShowTextOnImage: false,
        ...getDefaultDoppeActionCtaButtonSettings({
            buttonText: '',
            url: 'https://www.example.com',
            showButton: false,
        }),
        ...doppeActionContentSectionSettingsDefaultValuesMigration(overrides),
        ...doppeActionCtaButtonsSettingsDefaultValuesMigration(overrides, false),
        ...restOverrides
    } satisfies DoppeListActionSettings;
}

export function doppeListActionGetDefaultSearchListSettings(overrides?: Partial<DoppeListActionSettings.WithSearchSettings>) {

    const {
        listSearchSettings,
        ...restOverrides
    } = overrides ?? {}

    return {
        listSearchSettings: {
            ...doppeDtoActionGetDefaultSearchListSettingsProps(),
            ...listSearchSettings
        },
        ...restOverrides
    } satisfies DoppeListActionSettings.WithSearchSettings;
}



export function doppeListActionListItemGetDefaultListItemSettingsMigration(id: string, overrides?: Partial<DoppeListActionSettings.ListItemBaseSettings>) {
    return {
        id: id,
        enabled: true,
        deleted: false,
        displayInSearch: true,
        ...overrides
    } satisfies DoppeListActionSettings.ListItemBaseSettings;
}

export function doppeListActionGetItemClickBehaviourDefaultListSettings(overrides?: Partial<DoppeListActionSettings.WithItemClickBehaviour>) {
    return {
        itemClickBehaviour: DoppeActionListItemClickBehaviour.PreviewItemAsPopup,
        ...overrides
    } satisfies DoppeListActionSettings.WithItemClickBehaviour;
}

export function doppeListActionGetWithExternalDataSourceDefaultListSettings(overrides?: Partial<DoppeListActionSettings.WithExternalDataSourceSupport<any>>) {
    return {
        externalDataSource: null,
        externalDataSourceItemsCount: 4,
        ...overrides
    } satisfies DoppeListActionSettings.WithExternalDataSourceSupport<any>;
}

export function doppeListActionGetMainDetailsDefaultSettings(
    options: {
        defaultTitle?: string;
    } = {},
    overrides?: Partial<DoppeListActionSettings.WithMainDetails>,
) {

    const {
        mainMediaViewLayout,
        mainMediaViewSettings,
        ...restOverrides
    } = overrides ?? {}

    return {
        mainImage: doppeHideableValueCreateHidden(null),
        mainTitle: doppeHideableValueCreateHidden(options.defaultTitle ?? ''),
        ...doppeActionGetMediaViewDefaultSettingsMigration(overrides ?? {}),
        ...restOverrides
    } satisfies DoppeListActionSettings.WithMainDetails;
}

export function convertCommonListSettingToDefaultDoppeActionCtaButtonSettingsFromListAction(
    actionSettings: Partial<DoppeListActionSettings & DoppeListActionSettings.WithCtaButton & DoppeListActionSettings.DeprecatedFields>
) {
    return getDefaultDoppeActionCtaButtonSettings({
        buttonText: actionSettings.buttonText,
        url: actionSettings.buttonUrl ?? '',
        showButton: actionSettings.showButton !== undefined ? actionSettings.showButton : false,
    });
}

export function doppeListActionSettingsGetListItemCtaButtonsDefaultSettings() {
    return {
        customCtaButtons: [DoppeListActionSettingsCtaButtonAutoValue.ListTemplateButtons],
        ctaButtonsOverridesMap: {},
    } as ListItemWithCtaButtons;
}

export function doppeListActionSettingsGetListItemSectionsDefaultSettings() {
    return {
        customSections: [DoppeListActionSettingsContentSectionAutoValue.ListTemplateSections],
        sectionsOverridesMap: {},
    } satisfies ListItemWithSections;
}


export function doppeListActionSettingsFilterHiddenListItemsFromActionView<T extends DoppeListActionSettings.ListItemBaseSettings>(items?: T[]) {
    return items?.filter(doppeListActionSettingsIsListItemVisibleInAction) ?? [];
}

export function doppeListActionSettingsFilterDeletedListItemsFromActionView<T extends DoppeListActionSettings.ListItemBaseSettings>(items?: T[]) {
    return items?.filter(doppeListActionSettingsIsListItemIsNotDeleted) ?? [];
}


export function doppeListActionSettingsFilterHiddenListItemsFromSearchResults<T extends DoppeListActionSettings.ListItemBaseSettings>(items?: T[]) {
    return items?.filter(doppeListActionSettingsIsListItemVisibleInSearchResults) ?? [];
}

export function doppeListActionSettingsIsListItemVisibleInAction<T extends DoppeListActionSettings.ListItemBaseSettings>(item: T) {
    return item.enabled && !item.deleted;
}

export function doppeListActionSettingsIsListItemHiddenInActionAndNotDeleted<T extends DoppeListActionSettings.ListItemBaseSettings>(item: T) {
    return !item.enabled && !item.deleted;
}

export function doppeListActionSettingsIsListItemVisibleInSearchResults<T extends DoppeListActionSettings.ListItemBaseSettings>(item: T) {
    return doppeListActionSettingsIsListItemVisibleInAction(item) && item.displayInSearch;
}

export function doppeListActionSettingsIsListItemIsDeleted<T extends DoppeListActionSettings.ListItemBaseSettings>(item: T) {
    return item.deleted;
}

export function doppeListActionSettingsIsListItemIsNotDeleted<T extends DoppeListActionSettings.ListItemBaseSettings>(item: T) {
    return !item.deleted;
}

export function doppeListActionSettingsIsListItemIsVisible<T extends DoppeListActionSettings.ListItemBaseSettings>(item: T) {
    return item.enabled;
}

