import React from "react";
import {Button, Icon} from "@wix/devzai-utils-react";
import {classes, style} from './icon-button.st.css';

export const IconButton = React.memo(function IconButton (props: IconButton.Props) {
    const {
        icon,
        title,

        iconClassName,
        className,
        ...htmlAttributes
    } = props;

    return (
        <Button
            {...htmlAttributes}
            aria-label={title}
            className={style(classes.root, className)}
        >
            <Icon
                className={style(classes.icon, {}, iconClassName)}
                icon={icon}
            />
        </Button>
    );
});

export namespace IconButton {

    export interface Props extends Button.Props {
        icon: Icon.Props['icon'];
        title?: string;
        iconClassName?: string;
    }
}