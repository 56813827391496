import {
    doppeHideableValueGetVisibleValue,
    DoppeHtmlSiteInfo,
    DoppeHtmlSitePagesType,
    doppeHtmlSiteResolveAllProductsPageUrl,
    doppeHtmlSiteResolveBookOnlinePageUrl,
    doppeHtmlSiteResolveCartPageUrl
} from '../../../client-server-common';
import {
    autoValueIsAutoValue,
    evaluateFunction,
    stringTrimPrefix,
    uiPartCreateMarking,
    validateIsEmail,
    validateIsUrl
} from '@wix/devzai-utils-common';
import {DoppeActionCtaButtonsSettings} from '../../../client-server-common/types/doppe-action-cta-buttons-settings';
import {
    DoppeActionTypeWidgetInnerCheckoutButtonParts,
    doppeActionTypeWidgetInnerCheckoutButtonPartsCreateName
} from '../../../components/doppe-action-type-widget-inner-checkout-button/doppe-action-type-widget-inner-checkout-button.ui-parts';
import {
    DoppeActionCTAButton,
    doppeActionCtaButtonLinkResolveTarget,
    doppeActionCtaButtonTypeOpenUrl,
    DoppeActionCTAType,
    doppeActionMultipleCTAButtonIsVisible,
    DoppeActionSettingsCTAButtonSettings
} from '../../../client-server-common/types/doppe-action-cta-button';

export function doppeActionCtaButtonTypeGetLinkSettings(ctaButton: DoppeActionCTAButton.BaseType, options: {
    sourceURL?: string,
    actionId: string,
    innerItemId?: string,
    htmlSiteInfo?: DoppeHtmlSiteInfo,
}) {

    const {
        type,
    } = ctaButton.settings;

    const {
        actionId,
        innerItemId,
        sourceURL,
        htmlSiteInfo
    } = options ?? {}

    const xxx = evaluateFunction(() => {
        switch (type) {
            default: {

                const openURL = doppeActionCtaButtonTypeOpenUrl(ctaButton.settings);

                if (openURL) {
                    return {
                        href: doppeActionCtaButtonTypeResolveURL(ctaButton.settings, {
                            sourceURL: sourceURL,
                            htmlSiteInfo: htmlSiteInfo
                        }),
                        target: doppeActionCtaButtonLinkResolveTarget(ctaButton.settings),
                        rel: 'noopener',
                        tagName: 'a' as const,
                    }
                } else if (type === DoppeActionCTAType.Checkout) {
                    return {
                        ...uiPartCreateMarking(DoppeActionTypeWidgetInnerCheckoutButtonParts.Root, doppeActionTypeWidgetInnerCheckoutButtonPartsCreateName(actionId, innerItemId))
                    }
                } else {
                    return undefined;
                }

            }
        }
    })

    return xxx;
}

export function doppeActionCtaButtonsResolveMainURL(ctaButtonsSettings: DoppeActionCtaButtonsSettings, options: {
    sourceURL?: string,
    htmlSiteInfo: DoppeHtmlSiteInfo | null
}) {

    const {
        sourceURL,
        htmlSiteInfo
    } = options;

    for (let i = 0; i < ctaButtonsSettings.ctaButtons.length; i++) {
        const ctaButton = ctaButtonsSettings.ctaButtons[i];
        if (doppeActionMultipleCTAButtonIsVisible(ctaButton)) {
            const url = doppeActionCtaButtonTypeResolveURL(ctaButton.settings, {
                sourceURL: sourceURL,
                htmlSiteInfo: htmlSiteInfo
            });
            if (url) {
                return url;
            }
        }
    }

    return null;
}


export function doppeActionCtaButtonTypeResolveURL(ctaSettings: DoppeActionSettingsCTAButtonSettings, options: {
    sourceURL?: string,
    htmlSiteInfo?: DoppeHtmlSiteInfo | null
}) {

    const {
        sourceURL,
        htmlSiteInfo = null
    } = options;

    switch (ctaSettings.type) {
        case DoppeActionCTAType.Call: {
            return `tel:${ctaSettings.phone}`;
        }
        case DoppeActionCTAType.Email: {

            if (!validateIsEmail(ctaSettings.email)) {
                return undefined;
            }

            return `mailto:${ctaSettings.email}?subject=${encodeURI(doppeHideableValueGetVisibleValue(ctaSettings.customSubject, ''))}&body=${encodeURI(doppeHideableValueGetVisibleValue(ctaSettings.customMessage, ''))}`
        }
        case DoppeActionCTAType.Share:
        case DoppeActionCTAType.Link: {

            const url = autoValueIsAutoValue(ctaSettings.url) ? sourceURL ?? '' : ctaSettings.url;

            if (validateIsUrl(url, {
                protocols: ['http', 'https'],
                require_protocol: true
            })) {
                return url;
            } else {
                return '';
            }
        }
        case DoppeActionCTAType.IsracardPay: {

            const url = ctaSettings.url;

            if (validateIsUrl(url, {
                protocols: ['http', 'https'],
                require_protocol: true
            })) {
                return url;
            } else {
                return '';
            }
        }
        case DoppeActionCTAType.OpenPage: {
            if (htmlSiteInfo) {
                switch (ctaSettings.pageType) {
                    case DoppeHtmlSitePagesType.Store: {
                        return doppeHtmlSiteResolveAllProductsPageUrl(htmlSiteInfo) ?? undefined;
                    }
                    case DoppeHtmlSitePagesType.Cart: {
                        return doppeHtmlSiteResolveBookOnlinePageUrl(htmlSiteInfo) ?? undefined;
                    }
                    case DoppeHtmlSitePagesType.BookingServices: {
                        return doppeHtmlSiteResolveCartPageUrl(htmlSiteInfo) ?? undefined;
                    }
                    default: {
                        return undefined;
                    }
                }
            }

            return undefined
        }
        case DoppeActionCTAType.OpenProduct: {
            if (sourceURL && validateIsUrl(sourceURL, {
                protocols: ['http', 'https'],
                require_protocol: true
            })) {
                return sourceURL;
            } else {
                return '';
            }
        }
        case DoppeActionCTAType.Whatsapp: {
            return `https://api.whatsapp.com/send?phone=${stringTrimPrefix(ctaSettings.phone, '+')}&text=${encodeURI(doppeHideableValueGetVisibleValue(ctaSettings.customMessage, ''))}`;
        }
        case DoppeActionCTAType.Navigate: {
            if (!ctaSettings.address) {
                return undefined;
            }

            return `https://www.google.com/maps/dir/?api=1&destination=${encodeURI(ctaSettings.address)}`
        }
        case DoppeActionCTAType.FacebookMessage: {
            return ctaSettings.userName !== undefined ?
                `https://www.m.me/${ctaSettings.userName}?text=${encodeURI(doppeHideableValueGetVisibleValue(ctaSettings.customMessage, ''))}` :
                undefined;
        }
        case DoppeActionCTAType.InstagramMessage: {
            return ctaSettings.userName !== undefined ?
                `https://ig.me/m/${ctaSettings.userName}` :
                undefined;
        }
        case DoppeActionCTAType.Checkout:
        case DoppeActionCTAType.SaveContact: {
            return undefined;
        }
    }
}

