import React, {useImperativeHandle, useLayoutEffect, useMemo, useRef} from 'react';
import {DomElementResolver} from "../dom-element-resolver/dom-element-resolver";
import {
    ArrayOrSingle,
    asArray,
    Evaluable,
    evaluateWhenFunction, objectMapValues,
    OmitStrict,
    tsTypeAssert
} from "@wix/devzai-utils-common";
import {usePageIsRtl} from "../react-page-lang/react-page-lang";

export const DomElementAnimator = React.memo(React.forwardRef(function DomElementAnimator (props: DomElementAnimator.Props, ref: React.Ref<DomElementAnimator>) {
    const {
        autoAnimateOnValueChange,
        autoAnimateOnMount,
        onAppeared,
        children
    } = props;

    const domElementResolverRef = useRef<DomElementResolver>(null);

    const animationRef = useRef<Animation>()
    const pageIsRtl = usePageIsRtl();

    const elementAnimator = useMemo(() => {
        
        const controller = tsTypeAssert<DomElementAnimator>({
            stopCurrentAnimation () {
                animationRef.current?.cancel();

                animationRef.current = undefined;
            },
            animate (animationSpecResolver, onAnimationEnd) {

                // Abort previous animation if such exists.
                controller.stopCurrentAnimation();

                const domElement = domElementResolverRef.current?.getDomElement() as HTMLElement;

                if (!domElement) {
                    onAnimationEnd?.();
                } else {

                    const animationSpec = evaluateAnimationSpec(animationSpecResolver, domElement, {
                        pageIsRtl: pageIsRtl
                    });

                    if (!animationSpec) {
                        onAnimationEnd?.();
                    } else {
                        animationSpec.onBeforeAnimationStart?.(domElement);

                        const animation = animationRef.current = domElement.animate(animationSpec.animation, {
                            duration: animationSpec.durationMilliseconds,
                            fill: animationSpec.remainInTheLastStateAfterCompletion ? 'forwards' : undefined
                        })
                        
                        animation.onfinish = () => {
                            if (animationRef.current === animation) {
                                animationRef.current = undefined;
                            }
                            animationSpec.onAnimationEnd?.(domElement, true);
                            onAnimationEnd?.();
                        }

                        animation.oncancel = () => {
                            if (animationRef.current === animation) {
                                animationRef.current = undefined;
                            }
                            animationSpec.onAnimationEnd?.(domElement, false);
                            onAnimationEnd?.();
                        }
                    }
                }
            }
        })

        return controller;
    }, [])

    useImperativeHandle(ref, () => elementAnimator, [elementAnimator]);

    const isFirstValueChangeAnimation = useRef(true);

    useLayoutEffect(() => {
        if (autoAnimateOnValueChange) {
            if (isFirstValueChangeAnimation.current) {
                isFirstValueChangeAnimation.current = false;
            } else {
                elementAnimator.animate(autoAnimateOnValueChange.animationSpec);
            }
        }
    }, [autoAnimateOnValueChange?.value])

    useLayoutEffect(() => {
        if (autoAnimateOnMount) {
            elementAnimator.animate(autoAnimateOnMount, onAppeared);
        }

        return () => {
            elementAnimator.stopCurrentAnimation();
        }
    }, []);

    return (
        <DomElementResolver
            ref={domElementResolverRef}
        >
            {children}
        </DomElementResolver>
    );
}));

export interface DomElementAnimator {

    animate (
        animationSpec: DomElementAnimator.EvaluableAnimationSpec,
        onAnimationEnd?: () => void
    ) : void;
    stopCurrentAnimation () : void;
}

export namespace DomElementAnimator {

    export interface Props {
        autoAnimateOnValueChange?: {
            value: any;
            animationSpec: EvaluableAnimationSpec;
        };
        autoAnimateOnMount?: EvaluableAnimationSpec;
        children: React.ReactElement;
        onAppeared?: () => void;
    }

    export type AnimationSpecEvaluationContext = {
        pageIsRtl: boolean;
    }

    export type EvaluableAnimationSpec = Evaluable<(element: HTMLElement, context: AnimationSpecEvaluationContext) => (AnimationSpec | undefined)>;

    export interface AnimationSpec {
        animation: Parameters<Animatable['animate']>[0];
        onAnimationEnd?: (element: HTMLElement, finished: boolean) => void;
        onBeforeAnimationStart?: (element: HTMLElement) => void;
        remainInTheLastStateAfterCompletion?: boolean;
        durationMilliseconds: number;
        activeAnimationClassName?: Evaluable<(element: HTMLElement) => ArrayOrSingle<{element: HTMLElement, className: string}>>;
    }
}

export function domElementAnimatorCreateElementHighlightingAnimationSpec (
    options: {
        highlightedStateTransitionDurationMilliseconds: number;
        highlightedState: {
            [property: string]: string | number | null | undefined
        },
        highlightedStateDurationMilliseconds: number;
    } & OmitStrict<DomElementAnimator.AnimationSpec, 'animation' | 'durationMilliseconds'>
) : DomElementAnimator.AnimationSpec {

    const {
        highlightedStateTransitionDurationMilliseconds,
        highlightedState,
        highlightedStateDurationMilliseconds,
        ...restAnimationSpecProps
    } = options;

    const totalDuration = highlightedStateTransitionDurationMilliseconds * 2 + highlightedStateDurationMilliseconds;

    const edgeState = objectMapValues(highlightedState, () => '');
    const edgeOffsetInterval = highlightedStateTransitionDurationMilliseconds / totalDuration;

    return {
        animation: [
            edgeState,
            {...highlightedState, offset: edgeOffsetInterval},
            {...highlightedState, offset: 1 - edgeOffsetInterval},
            edgeState
        ],
        durationMilliseconds: totalDuration,
        ...restAnimationSpecProps
    }
}

function evaluateAnimationSpec (
    evaluableAnimationSpec: DomElementAnimator.EvaluableAnimationSpec,
    element: HTMLElement,
    context: DomElementAnimator.AnimationSpecEvaluationContext
) : DomElementAnimator.AnimationSpec | undefined {

    const animationSpec = evaluateWhenFunction(evaluableAnimationSpec, element, context);

    if (animationSpec) {

        const {
            activeAnimationClassName
        } = animationSpec;

        if (activeAnimationClassName) {

            const {
                onBeforeAnimationStart,
                onAnimationEnd
            } = animationSpec;

            let classNamesTargetsArr: {element: HTMLElement, className: string}[] = [];

            return {
                ...animationSpec,
                onBeforeAnimationStart: element => {
                    onBeforeAnimationStart?.(element);

                    classNamesTargetsArr = asArray(evaluateWhenFunction(activeAnimationClassName, element))

                    for (const classNameTarget of classNamesTargetsArr) {
                        classNameTarget.element.classList.add(classNameTarget.className)
                    }
                },
                onAnimationEnd: (element, finished) => {
                    onAnimationEnd?.(element, finished);

                    for (const classNameTarget of classNamesTargetsArr) {
                        classNameTarget.element.classList.remove(classNameTarget.className)
                    }
                }
            }
        } else {
            return animationSpec;
        }
    } else {
        return undefined;
    }

}