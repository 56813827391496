import React, {useContext, useImperativeHandle, useRef} from "react";
import {evaluateWhenFunction, EventEmitter, EventReference} from "@wix/devzai-utils-common";
import {IInnerWindowClosingController} from '../windows/inner-window'
import {DomElementAnimator} from "../dom-element-animator/dom-element-animator";
import {useInstanceValue} from "../hooks";
import {usePageIsRtl} from "../react-page-lang/react-page-lang";

const InnerWindowAnimatorContext = React.createContext<InnerWindowAnimator | null>(null);

export type InnerWindowAnimatorEvents = {
    eventOnClosingAnimationStart: void;
    eventOnClosingAnimationEnd: void;
}

export const InnerWindowAnimator = React.memo(function InnerWindowAnimator (props: InnerWindowAnimator.Props) {
    const {
        children,
        windowClosingControllerRef,
        openTransition,
        closeTransition
    } = props;

    const elementAnimatorRef = useRef<DomElementAnimator>(null);

    const innerWindowAnimator = useInstanceValue(() => {

        const eventEmitter = new EventEmitter<InnerWindowAnimatorEvents>();

        return {
            eventEmitter: eventEmitter,
            eventOnClosingAnimationStart: eventEmitter.createEventReference('eventOnClosingAnimationStart'),
            eventOnClosingAnimationEnd: eventEmitter.createEventReference('eventOnClosingAnimationEnd'),
        };
    })

    const pageIsRtl = usePageIsRtl();

    useImperativeHandle(windowClosingControllerRef, () => {
        return {
            handleWindowClosing(closeCallback: () => void) {

                const elementAnimator = elementAnimatorRef.current;
                if (elementAnimator && closeTransition !== undefined) {

                    elementAnimator.animate(element => {
                        const closeTransitionSpec = evaluateWhenFunction(closeTransition, element, {
                            pageIsRtl: pageIsRtl
                        });

                        if (!closeTransitionSpec) {
                            closeCallback();

                            return undefined;
                        } else {
                            return {
                                ...closeTransitionSpec,
                                onBeforeAnimationStart: () => {
                                    innerWindowAnimator.eventEmitter.emit('eventOnClosingAnimationStart');
                                },
                                onAnimationEnd: (element, finished) => {
                                    innerWindowAnimator.eventEmitter.emit('eventOnClosingAnimationEnd');

                                    closeCallback();
                                    closeTransitionSpec.onAnimationEnd?.(element, finished);
                                }
                            }
                        }
                    })

                } else {
                    closeCallback();
                }
            }
        }
    })

    return (
        <InnerWindowAnimatorContext.Provider
            value={innerWindowAnimator}
        >
            <DomElementAnimator
                ref={elementAnimatorRef}
                autoAnimateOnMount={openTransition}
            >
                {children}
            </DomElementAnimator>
        </InnerWindowAnimatorContext.Provider>
    )
});

export interface InnerWindowAnimator {
    eventOnClosingAnimationStart: EventReference<InnerWindowAnimatorEvents, "eventOnClosingAnimationStart">
    eventOnClosingAnimationEnd: EventReference<InnerWindowAnimatorEvents, "eventOnClosingAnimationEnd">
}

export namespace InnerWindowAnimator {
    export interface Props {
        windowClosingControllerRef: React.Ref<IInnerWindowClosingController>;
        children: React.ReactElement;
        openTransition?: DomElementAnimator.EvaluableAnimationSpec;
        closeTransition?: DomElementAnimator.EvaluableAnimationSpec;
    }
}

export function useInnerWindowAnimator () {
    return useContext(InnerWindowAnimatorContext);
}