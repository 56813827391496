import {LanguageCodes, languageIsRtl} from "@wix/devzai-utils-common";
import React, {useContext} from "react";

const ReactPageLangContext = React.createContext<LanguageCodes | null>(null);

export const ReactPageLangProvider = ReactPageLangContext.Provider;

export function usePageLang () {
    return useContext(ReactPageLangContext);
}

export function usePageIsRtl () {
    const lang = usePageLang();

    return lang !== null && languageIsRtl(lang);
}