import {
    assertDefined,
    cachedValueCreate,
    evaluateFunction,
    FontWeight,
    LanguageScript,
    languageScriptResolveFromLanguageCode,
    mapCreateFromIterable,
    objectMapValues,
    PartiallyOptional,
    TypefaceClassification,
    Values
} from "@wix/devzai-utils-common";
import {wixFontResolveGoogleFontStyleSheetUrl} from "@wix/devzai-common-wix";
import {DoppeViewerLang} from "../../client-server-common/doppe-viewer/doppe-viewer-lang";

export const DoppePageFontSource = {
    GoogleFont: 'GoogleFont'
} as const;

export type DoppePageFontSource = Values<typeof DoppePageFontSource>;

export type DoppePageFontSpec = {
    fontFamily: string;
    addedPixels: number;
    source: DoppePageFontSource;
    textFontWeight: FontWeight;
    headingsFontWeight: FontWeight;
    typefaceSpecification: TypefaceClassification;
    supportedLanguageScripts: LanguageScript[];
    isPremium: boolean;
}

function defineFontSpec (
    fontSpec: PartiallyOptional<DoppePageFontSpec,
        'isPremium' | 'textFontWeight' | 'headingsFontWeight' | 'addedPixels' | 'supportedLanguageScripts'>
) : DoppePageFontSpec {
    return {
        addedPixels: 0,
        textFontWeight: '400',
        headingsFontWeight: '600',
        isPremium: false,
        supportedLanguageScripts: [LanguageScript.Latin],
        ...fontSpec
    }
}

export const DoppePageSupportedFontFamiliesSpecs = {
    Poppins: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Poppins',
        typefaceSpecification: TypefaceClassification.sansSerif
    }),
    MadeFor: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Wix Madefor Text',
        typefaceSpecification: TypefaceClassification.sansSerif
    }),
    Rubik: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Rubik',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Hebrew],
        isPremium: true
    }),
    NanumMyeongjo: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Nanum Myeongjo',
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [LanguageScript.Latin],
        isPremium: true
    }),
    Fraunces: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Fraunces',
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [LanguageScript.Latin],
        isPremium: true
    }),
    Outfit: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Outfit',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin],
        isPremium: true
    }),
    Inter: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Inter',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Greek, LanguageScript.Cyrillic],
        isPremium: true
    }),
    Radley: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Radley',
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [LanguageScript.Latin],
        isPremium: true
    }),
    Arimo: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Arimo',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Greek, LanguageScript.Cyrillic, LanguageScript.Hebrew],
        isPremium: true
    }),
    Baloo2: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Baloo 2',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Devanagari],
        isPremium: true
    }),
    Tinos: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Tinos',
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Greek, LanguageScript.Cyrillic, LanguageScript.Hebrew],
        isPremium: true
    }),
    Besley: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Besley',
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [LanguageScript.Latin],
        isPremium: true
    }),
    CrimsonText: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Crimson Text',
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [LanguageScript.Latin],
        isPremium: true
    }),
    Bitter: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Bitter',
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Cyrillic],
        isPremium: true
    }),
    Syne: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Syne',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Greek],
        textFontWeight: '400',
        headingsFontWeight: '700',
        isPremium: true
    }),
    OldStandardTT: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Old Standard TT',
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Cyrillic],
        textFontWeight: '400',
        headingsFontWeight: '700',
        isPremium: true
    }),
    GreatVibes: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Great Vibes',
        typefaceSpecification: TypefaceClassification.cursive,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Cyrillic],
    }),
    Unbounded: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Unbounded',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Cyrillic],
        textFontWeight: '200',
        headingsFontWeight: '600',
        isPremium: true
    }),
    DancingScript: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Dancing Script',
        typefaceSpecification: TypefaceClassification.cursive,
        supportedLanguageScripts: [LanguageScript.Latin],
        textFontWeight: '400',
        headingsFontWeight: '700',
        isPremium: true
    }),
    Assistant: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Assistant',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Hebrew],
    }),
    MiriamLibre: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Miriam Libre',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Hebrew],
        textFontWeight: '400',
        headingsFontWeight: '700',
    }),
    IBMPlexSansHebrew: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'IBM Plex Sans Hebrew',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Hebrew],
        textFontWeight: '400',
        headingsFontWeight: '700',
    }),
    DavidLibre: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'David Libre',
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Hebrew],
        textFontWeight: '400',
        headingsFontWeight: '700',
    }),
    MPLUS1: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'M PLUS 1',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Hebrew],
        textFontWeight: '400',
        headingsFontWeight: '700',
    }),
    Heebo: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Heebo',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Hebrew],
        textFontWeight: '400',
        headingsFontWeight: '600',
    }),
    Alef: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Alef',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Hebrew],
        headingsFontWeight: '700',
    }),
    PlayfairDisplay: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Playfair Display',
        headingsFontWeight: '700',
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [LanguageScript.Cyrillic, LanguageScript.Latin, LanguageScript.Vietnamese]
    }),
    Merriweather: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Merriweather',
        headingsFontWeight: '700',
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [LanguageScript.Cyrillic, LanguageScript.Latin, LanguageScript.Vietnamese]
    }),
    RobotoSlab: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Roboto Slab',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [LanguageScript.Cyrillic, LanguageScript.Greek, LanguageScript.Latin, LanguageScript.Vietnamese]
    }),
    Roboto: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Roboto',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Cyrillic, LanguageScript.Greek, LanguageScript.Latin, LanguageScript.Vietnamese]
    }),
    Lora: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Lora',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [LanguageScript.Cyrillic, LanguageScript.Latin, LanguageScript.Vietnamese]
    }),
    OpenSans: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Open Sans',
        headingsFontWeight: '700',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Cyrillic, LanguageScript.Greek, LanguageScript.Hebrew, LanguageScript.Latin, LanguageScript.Vietnamese]
    }),
    SpaceMono: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Space Mono',
        headingsFontWeight: '700',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.monospace,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Vietnamese],
        textFontWeight: '400'
    }),
    Montserrat: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Montserrat',
        headingsFontWeight: '700',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Cyrillic, LanguageScript.Latin, LanguageScript.Vietnamese]
    }),
    Oswald: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Oswald',
        headingsFontWeight: '500',
        addedPixels: 2,
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Cyrillic, LanguageScript.Latin, LanguageScript.Vietnamese]
    }),
    Cormorant: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Cormorant',
        headingsFontWeight: '700',
        addedPixels: 2,
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [LanguageScript.Cyrillic, LanguageScript.Latin, LanguageScript.Vietnamese]
    }),
    JosefinSans: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Josefin Sans',
        headingsFontWeight: '700',
        addedPixels: 2,
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Vietnamese]
    }),
    Kalam: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Kalam',
        headingsFontWeight: '700',
        addedPixels: 2,
        typefaceSpecification: TypefaceClassification.cursive,
        supportedLanguageScripts: [LanguageScript.Devanagari, LanguageScript.Latin]
    }),
    Lato: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Lato',
        headingsFontWeight: '700',
        addedPixels: 2,
        typefaceSpecification: TypefaceClassification.sansSerif
    }),
    Caveat: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Caveat',
        headingsFontWeight: '700',
        addedPixels: 7,
        typefaceSpecification: TypefaceClassification.cursive,
        supportedLanguageScripts: [LanguageScript.Cyrillic, LanguageScript.Latin]
    }),
    Raleway: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Raleway',
        headingsFontWeight: '700',
        addedPixels: 0,
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Cyrillic, LanguageScript.Latin, LanguageScript.Vietnamese]
    }),
    Ubuntu: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Ubuntu',
        headingsFontWeight: '700',
        addedPixels: 0,
        supportedLanguageScripts: [LanguageScript.Cyrillic, LanguageScript.Greek, LanguageScript.Latin],
        typefaceSpecification: TypefaceClassification.sansSerif
    }),
    KiteOne: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Kite One',
        headingsFontWeight: '400',
        addedPixels: 0,
        typefaceSpecification: TypefaceClassification.sansSerif
    }),
    NovaRound: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Nova Round',
        headingsFontWeight: '400',
        addedPixels: 0,
        typefaceSpecification: TypefaceClassification.cursive
    }),
    Quicksand: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Quicksand',
        headingsFontWeight: '600',
        addedPixels: 0,
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Vietnamese]
    }),
    HeptaSlab: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Hepta Slab',
        headingsFontWeight: '600',
        addedPixels: 0,
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Vietnamese]
    }),
    LeagueSpartan: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'League Spartan',
        headingsFontWeight: '600',
        addedPixels: 2,
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Vietnamese]
    }),
    NunitoSans: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Nunito Sans',
        headingsFontWeight: '700',
        addedPixels: 2,
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Cyrillic, LanguageScript.Latin, LanguageScript.Vietnamese]
    }),
    Baskervville: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Baskervville',
        headingsFontWeight: '400',
        addedPixels: 2,
        typefaceSpecification: TypefaceClassification.serif
    }),
    Marcellus: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Marcellus',
        headingsFontWeight: '400',
        addedPixels: 2,
        typefaceSpecification: TypefaceClassification.serif
    }),
    SourceSans: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Source Sans 3',
        headingsFontWeight: '700',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Cyrillic, LanguageScript.Greek, LanguageScript.Latin, LanguageScript.Vietnamese],
        isPremium: true
    }),
    PTSans: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'PT Sans',
        headingsFontWeight: '700',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Cyrillic, LanguageScript.Latin],
        isPremium: true
    }),
    Prompt: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Prompt',
        headingsFontWeight: '700',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Thai, LanguageScript.Vietnamese],
        isPremium: true
    }),
    WorkSans: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Work Sans',
        headingsFontWeight: '700',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Vietnamese],
        isPremium: true
    }),
    Kanit: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Kanit',
        headingsFontWeight: '700',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Thai, LanguageScript.Vietnamese],
        isPremium: true
    }),
    Gluten: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Gluten',
        headingsFontWeight: '600',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.cursive,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Vietnamese],
        isPremium: true
    }),
    Orbitron: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Orbitron',
        headingsFontWeight: '800',
        addedPixels: 0,
        typefaceSpecification: TypefaceClassification.cursive,
        isPremium: true
    }),
    Rowdies: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Rowdies',
        textFontWeight: '300',
        headingsFontWeight: '700',
        addedPixels: 1,
        typefaceSpecification: TypefaceClassification.cursive,
        supportedLanguageScripts: [LanguageScript.Latin, LanguageScript.Vietnamese],
        isPremium: true
    }),
    Corben: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Corben',
        textFontWeight: '400',
        headingsFontWeight: '700',
        addedPixels: 0,
        typefaceSpecification: TypefaceClassification.cursive,
        isPremium: true
    }),
    Coda: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Coda',
        textFontWeight: '400',
        headingsFontWeight: '800',
        addedPixels: 0,
        typefaceSpecification: TypefaceClassification.cursive,
        isPremium: true
    }),
    NotoNaskh: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Noto Naskh Arabic',
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [LanguageScript.Arabic],
    }),
    NotoKufi: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Noto Kufi Arabic',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Arabic],
    }),
    NotoSans: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Noto Sans',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [
            LanguageScript.Latin,
            LanguageScript.Cyrillic,
            LanguageScript.Devanagari,
            LanguageScript.Greek,
            LanguageScript.Vietnamese
        ]
    }),
    LibreFranklin: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Libre Franklin',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin],
        isPremium: true
    }),
    FiraSans: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Fira Sans',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin],
        isPremium: true
    }),
    Geologica: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Geologica',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [LanguageScript.Latin],
        isPremium: true
    }),
    EBGaramond: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'EB Garamond',
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [LanguageScript.Latin],
        isPremium: true
    }),
    NotoSerif: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Noto Serif',
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [
            LanguageScript.Latin,
            LanguageScript.Cyrillic,
            LanguageScript.Greek,
            LanguageScript.Vietnamese
        ]
    }),
    NotoSansJP: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Noto Sans JP',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [
            LanguageScript.Japanese,
        ]
    }),
    NotoSerifJP: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Noto Serif JP',
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [
            LanguageScript.Japanese,
        ]
    }),
    NotoSansKR: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Noto Sans KR',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [
            LanguageScript.Korean,
        ]
    }),
    NotoSerifKR: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Noto Serif KR',
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [
            LanguageScript.Korean,
        ]
    }),
    NotoSansSC: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Noto Sans SC',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [
            LanguageScript.Chinese,
        ]
    }),
    NotoSerifSC: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Noto Serif SC',
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [
            LanguageScript.Chinese,
        ]
    }),
    NotoSansThai: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Noto Sans Thai',
        typefaceSpecification: TypefaceClassification.sansSerif,
        supportedLanguageScripts: [
            LanguageScript.Thai,
        ]
    }),
    NotoSerifThai: defineFontSpec({
        source: DoppePageFontSource.GoogleFont,
        fontFamily: 'Noto Serif Thai',
        typefaceSpecification: TypefaceClassification.serif,
        supportedLanguageScripts: [
            LanguageScript.Thai,
        ]
    }),
    // BodoniModa: defineFontSpec({
    //     source: DoppePageFontSource.GoogleFont,
    //     fontFamily: 'Bodoni Moda',
    //     textFontWeight: '400',
    //     headingsFontWeight: '700', // TODO: Requires ITALIC
    //     addedPixels: 1,
    //     typefaceSpecification: TypefaceClassification.serif,
    //     isPremium: true
    // }),
    // DMSerifDisplay: defineFontSpec({
    //     source: DoppePageFontSource.GoogleFont,
    //     fontFamily: 'DM Serif Display',
    //     textFontWeight: '400', // TODO: Requires ITALIC
    //     headingsFontWeight: '400',
    //     addedPixels: 1,
    //     typefaceSpecification: TypefaceClassification.serif,
    //     isPremium: true
    // }),
} as const satisfies Record<string, DoppePageFontSpec>;

type DoppePageSupportedFontFamiliesSpecs = typeof DoppePageSupportedFontFamiliesSpecs;

const DoppePageSupportedFontFamilies = objectMapValues(DoppePageSupportedFontFamiliesSpecs, spec => spec.fontFamily) as {
    [K in keyof DoppePageSupportedFontFamiliesSpecs]: DoppePageSupportedFontFamiliesSpecs[K]['fontFamily']
};

const lazyFontFamilyToFontSpecMap = cachedValueCreate(() => {
    return mapCreateFromIterable(
        Object.values(DoppePageSupportedFontFamiliesSpecs),
        pageFontSpec => pageFontSpec.fontFamily
    )
})

function doppePageFontResolveFontSpecFromFontFamily (fontFamily: string) {
    return lazyFontFamilyToFontSpecMap.getValue().get(fontFamily);
}

export function doppePageFontFamilyIsPremium (fontFamily: string) {

    const fontSpec = doppePageFontResolveFontSpecFromFontFamily(fontFamily);

    return fontSpec?.isPremium ?? false;
}

export function doppePageFontSpecIsSupportedForLanguage (spec: DoppePageFontSpec, lang: DoppeViewerLang) {
    return spec.supportedLanguageScripts.includes(languageScriptResolveFromLanguageCode(lang));
}

export function doppePageFontGetSupportedFontFamiliesSpecs (lang: DoppeViewerLang) {
    return Object.values(DoppePageSupportedFontFamiliesSpecs).filter(spec => {
        return doppePageFontSpecIsSupportedForLanguage(spec, lang) && spec.addedPixels < 2
    })
}


export function doppePageFontGetSupportedFontFamilies (lang: DoppeViewerLang) {
    return doppePageFontGetSupportedFontFamiliesSpecs(lang).map(spec => spec.fontFamily);
}

export function doppePageFontEnsureSupportedFontSpec (
    fontFamily: string,
    doppeViewerLang: DoppeViewerLang
) {
    const fontSpec = doppePageFontResolveFontSpecFromFontFamily(fontFamily);

    if (fontSpec === undefined || !doppePageFontSpecIsSupportedForLanguage(fontSpec, doppeViewerLang)) {
        return assertDefined(doppePageFontResolveFontSpecFromFontFamily(doppePageFontGetDefaultFontFamily(doppeViewerLang)))
    } else {
        return fontSpec
    }
}

export type DoppePageSupportedFontFamilies = Values<typeof DoppePageSupportedFontFamilies>;

const DefaultFontFamilyByLanguageScript = {
    [LanguageScript.Latin]: 'Poppins',
    [LanguageScript.Devanagari]: DoppePageSupportedFontFamilies.NotoSans,
    [LanguageScript.Cyrillic]: DoppePageSupportedFontFamilies.NotoSans,
    [LanguageScript.Hebrew]: 'Rubik',
    [LanguageScript.Thai]: DoppePageSupportedFontFamilies.NotoSansThai,  // TODO
    [LanguageScript.Japanese]: DoppePageSupportedFontFamilies.NotoSansJP,
    [LanguageScript.Korean]: DoppePageSupportedFontFamilies.NotoSansKR,
    [LanguageScript.Chinese]: DoppePageSupportedFontFamilies.NotoSansSC,
    [LanguageScript.Arabic]: DoppePageSupportedFontFamilies.NotoNaskh,
    [LanguageScript.Greek]: DoppePageSupportedFontFamilies.NotoSans,
    [LanguageScript.Vietnamese]: DoppePageSupportedFontFamilies.NotoSans,
} satisfies Record<LanguageScript, DoppePageSupportedFontFamilies>;

export function doppePageFontGetDefaultFontFamily (doppeViewerLang: DoppeViewerLang) : DoppePageSupportedFontFamilies {
    return DefaultFontFamilyByLanguageScript[languageScriptResolveFromLanguageCode(doppeViewerLang)]
}

export function doppePageFontResolveStyleSheetUrl<F extends string> (
    options: {
        fontFamily: F;
        fontWeights: FontWeight[];
        text?: string;
    }
) {

    const {
        fontFamily,
        fontWeights,
        text
    } = options;

    const fontSpec = doppePageFontResolveFontSpecFromFontFamily(fontFamily);

    const result = evaluateFunction(() => {
        if (fontSpec) {
            switch (fontSpec.source) {
                case DoppePageFontSource.GoogleFont:
                    return wixFontResolveGoogleFontStyleSheetUrl({
                        fontFamily: fontSpec.fontFamily,
                        fontWeights: fontWeights,
                        text: text
                    })
            }
        } else {
            return undefined;
        }
    })

    return result as F extends DoppePageSupportedFontFamilies ? string : (string | undefined)
}